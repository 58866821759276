<template>
  <div v-if="reservation">
    <ui-section-header>
      <template v-slot:title
        >Package</template
      >
    </ui-section-header>

    <table
      v-if="pckg"
      class="table is-fullwidth is-striped is-hoverable is-narrow"
    >
      <thead>
        <tr>
          <th width="75" class="has-text-left">Qty.</th>
          <th>Name</th>
          <th width="100" class="has-text-right">Price</th>
          <th>&nbsp;</th>
          <th width="100" class="has-text-right">Total</th>
          <th width="80">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ reservation.TotalSeats }}x</td>
          <td>{{ pckg.Name }}</td>
          <td class="has-text-right">
            {{
              pckg.VoucherPriceExclTax
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td>&nbsp;</td>
          <td width="100" class="has-text-right">
            {{
              (pckg.VoucherPriceExclTax * reservation.TotalSeats)
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    pckg() {
      let p = null

      if (this.reservation && this.reservation.Voucher) {
        p = this.reservation.Voucher
      }

      return p
    },
  },

  created() {},

  methods: {},
}
</script>
